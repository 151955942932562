// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/hooks/useFeatures.ts"
);
import.meta.hot.lastModified = "1733761746631.927";
}
// REMIX HMR END

import { useTypedRouteLoaderData } from "remix-typedjson";
import { loader } from "../root";
import type { TriggerFeatures } from "~/features.server";

export function useFeatures(): TriggerFeatures {
  const routeMatch = useTypedRouteLoaderData<typeof loader>("root");

  return routeMatch?.features ?? { isManagedCloud: false, v3Enabled: false };
}
